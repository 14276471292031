<template>
  <v-app>
    <v-main class="accent">
      <v-container fill-height>
        <v-row justify="center">
          <v-col cols="auto">
            <v-card class="rounded-md _box-shadow">
              <v-card-text class="pa-8">
                <v-row justify="center" align="center">
                  <!-- <v-col cols="auto">
                    <p class="header--text display-4 font-weight-black ma-0">
                      404 🤷‍♂️
                    </p>
                  </v-col>

                  <v-divider class="header mx-8" vertical /> -->

                  <v-col cols="auto">
                    <p class="header--text text-h1 font-weight-bold">
                      404 🤷‍♂️
                    </p>
                    <p class="text-h6">
                      The page you’re looking for was not found.
                    </p>

                    <v-btn
                      color="primary"
                      class="text-none py-6 rounded-md"
                      depressed
                      block
                      @click="$router.push({ name: 'sign-in' })"
                    >
                      Back to home
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="transparent" class="justify-center pa-4" app>
      <small class="info--text">&copy; 2022 Profit1 Systems Ltd.</small>
    </v-footer>
  </v-app>
</template>

<script>
const TITLE = "Profit1 Affiliate Network";

export default {
  name: "404",

  data: () => ({
    pageLoaded: false
  }),

  computed: {
    isReady: vm => vm.pageLoaded
  },

  watch: {
    isReady(value) {
      if (value) this.hidePreloader();
    }
  },

  created() {
    document.title = `Error 404 :: ${TITLE}`;
    window.addEventListener("load", () => (this.pageLoaded = true));
  },

  methods: {
    timeout() {
      return new Promise(resolve => setTimeout(resolve, 500));
    },

    async hidePreloader() {
      const preloader = document.getElementById("preloader");
      const timeout = this.timeout;

      await timeout();

      preloader?.classList.add("fade-out");
      setTimeout(() => preloader?.remove(), 500);
    }
  }
};
</script>
